<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <!--<transition-->
  <!--  name="fade"-->
  <!--  mode="out-in"-->
  <!--&gt;-->
  <slide-fade-transition>
    <router-view
      :name="name"
      v-bind="$attrs"
      v-on="$listeners"
    />
    <slot />
  </slide-fade-transition>
</template>

<script>

import SlideFadeTransition from '@components/SlideFadeTransition'

export default {
  name: 'LayoutRouterView',
  components: { SlideFadeTransition },
  props: {
    name: {
      type: String,
      default: () => undefined
    }
  },
  data: () => ({})
}
</script>

